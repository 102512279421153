<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New On-Call Service
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
         <v-alert outlined dismissible icon="mdi-phone-in-talk" prominent type="info">
          <h3>Services allow UnStruct to resolve on-call personnel from your on-call provider (e.g., PagerDuty, Opsgenie, etc.). Here's how you can leverage services:</h3>
            <ul>
              <li>Create a service for each team or group of people to automatically add as participants when an incident is triggered.</li>
              <li>Create a service for each escalation policy that you want to notify when an incident is triggered.</li>
              <li>Enable an on-call plugin to automatically create services for you.</li>
            </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
          <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
            <v-card class="ma-2 elevation-3">
              <v-card-title>
                <v-icon left>mdi-web</v-icon>
                <service :service="item" />
                <v-spacer></v-spacer>
                <v-chip v-if="item.is_active" color="blue" dark class="mr-2">
                  <v-icon left>mdi-lightbulb-on</v-icon>
                  Active
                </v-chip>
                <v-chip v-if="!item.is_active" dark class="mr-2">
                  <v-icon left>mdi-lightbulb-off-outline</v-icon>
                  Disabled
                </v-chip>
              </v-card-title>

              <v-card-subtitle>
                <div v-for="filter in item.filters" :key="filter.id" class="my-2">
                  <v-icon small left>mdi-filter-outline</v-icon>
                  <search-filter :filter="filter" />
                </div>
              </v-card-subtitle>

              <v-card-text>
                <v-chip v-if="item.health_metrics" color="success" dark class="mr-2">
                  <v-icon left>mdi-clipboard-pulse-outline</v-icon>
                  Health Metrics
                </v-chip>
                <v-chip v-if="!item.health_metrics" dark class="mr-2">
                  <v-icon left>mdi-gauge-empty</v-icon>
                  No Health Metrics
                </v-chip>
                <v-chip v-if="item.evergreen" color="success" dark class="mr-2">
                  <v-icon left>mdi-autorenew</v-icon>
                  Evergreen
                </v-chip>
                <v-chip v-if="!item.evergreen" dark class="mr-2">
                  <v-icon left>mdi-pause-circle-outline</v-icon>
                  Not Evergreen
                </v-chip>
              </v-card-text>

              <v-card-text>
                <div>
                  <v-icon small left>mdi-text</v-icon>
                  {{ item.description }}
                </div>
                <div>
                  <v-icon small left>mdi-format-superscript</v-icon>
                  {{ item.type }}
                </div>
                <div>
                  <v-icon small left>mdi-key-variant</v-icon>
                  {{ item.external_id }}
                </div>
                <div>
                  <v-icon small left>mdi-account-outline</v-icon>
                  {{ item.evergreen_owner }}
                </div>
              </v-card-text>

              <v-card-actions class="justify-end">
                <v-btn color="primary" text @click="createEditShow(item)">Edit</v-btn>
                <v-btn color="error" text @click="removeShow(item)">Delete</v-btn>
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="createEditShow(item)">
                      <v-list-item-title>View / Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="removeShow(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/service/DeleteDialog.vue"
import NewEditSheet from "@/service/NewEditSheet.vue"
import Service from "@/service/Service.vue"
import SearchFilter from "@/search/SearchFilter.vue"

export default {
  name: "ServiceTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
    SearchFilter,
    Service,
  },

  computed: {
    ...mapFields("service", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("service", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
