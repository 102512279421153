<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
      <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
        <template v-slot:activator="{ on }">
          <v-chip pill small v-on="on">
            <v-avatar color="error" left>
              <span class="white--text">{{ service.name | initials }}</span>
            </v-avatar>
            {{ service.name }}
          </v-chip>
        </template>
        <v-card width="300">
          <v-list dark>
            <v-list-item>
              <v-list-item-avatar color="error">
                <span class="white--text">{{ service.name | initials }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ service.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ service.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="menu = false">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-web</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ service.type }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>business</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ service.external_id || "Unknown" }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <v-icon v-if="service.is_active" color="success">mdi-server</v-icon>
                    <v-icon v-else color="grey">mdi-server</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle>{{ service.is_active ? 'Active' : 'Inactive' }}</v-list-item-subtitle>
                </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </template>

  <script>
  export default {
    name: "Service",

    data: () => ({
      menu: false,
    }),

    props: {
      service: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
  }
  </script>
